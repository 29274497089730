const RocketImg = (data) => {
	return (
		<>
			<img
				src={data.image}
				className='launch__img launch__copy'
				alt={data.alt}
			/>
		</>
	);
};

export default RocketImg;
