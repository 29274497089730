import React, { useEffect, useState } from 'react';
import logo from './assets/SpaceX-Logo.svg';
import axios from './api/axios';
import Button from 'react-bootstrap/Button';
import LaunchCard from './components/launch-card';

import './App.scss';

function App() {
	const [launchData, setLaunchDate] = useState({});
	let [firstCard, setFirstCard] = useState(undefined);
	let [card1, setcard1] = useState({});
	let [card2, setcard2] = useState({});
	let [card3, setcard3] = useState({});

	let [btnMinus, setBtnMinus] = useState(true);
	let [btnPlus, setBtnPlus] = useState(false);

	const tableClickHandler = (direction) => {
		let newFirstCard = firstCard + direction;
		setBtnMinus(false);
		setBtnPlus(false);

		if (newFirstCard === 0) {
			newFirstCard = 0;
			setBtnMinus(true);
		}
		if (newFirstCard === launchData.length - 3) {
			newFirstCard = launchData.length - 3;
			setBtnPlus(true);
		}

		setFirstCard(newFirstCard);
	};

	useEffect(() => {
		const fetchLaunches = async () => {
			await axios.get('/launches/upcoming').then((result) => {
				result.data.sort((a, b) =>
					a.date_local > b.date_local ? 1 : -1
				);
				setLaunchDate(result.data);
				setFirstCard(0);
			});
		};

		fetchLaunches();
	}, []);

	useEffect(() => {
		if (firstCard !== undefined) {
			setcard1(launchData[firstCard]);
			setcard2(launchData[firstCard + 1]);
			setcard3(launchData[firstCard + 2]);
		}
	}, [firstCard, launchData]);

	return (
		<section id='SpaceX'>
			<div className='col-md-12 launchHeader'>
				<img src={logo} alt='Space-X logo.' />
				<h2
					className='homeH2'
					aria-label='Upcoming SpaceX Launchess'
					tabIndex='0'>
					Upcoming Launches{' '}
					<Button
						onClick={() => tableClickHandler(-1)}
						disabled={btnMinus}
						id='btnMinus'>
						{' '}
						-{' '}
					</Button>
					<Button
						onClick={() => tableClickHandler(1)}
						disabled={btnPlus}>
						{' '}
						+{' '}
					</Button>
				</h2>
				<div id='launchBlockHolder'>
					<LaunchCard data={card1} />
					<LaunchCard data={card2} />
					<LaunchCard data={card3} />
				</div>
			</div>
		</section>
	);
}

export default App;
