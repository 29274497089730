import React, { useEffect, useState, useLayoutEffect } from 'react';
import moment from 'moment';
import axios from '../api/axios';
import RocketImg from './rocket-img';
import Spinner from './spinner';
import falcon9 from '../assets/falcon9.png';
import falconheavy from '../assets/falconheavy.png';
import dragon from '../assets/dragon.png';
import starship from '../assets/starship.png';
import './launch-card.scss';

const LaunchCard = (data) => {
	const [rocket, setRocket] = useState('Loading');
	const [altText, setAltText] = useState('');
	const [image, setImage] = useState();
	const [launchpad, setLaunchpad] = useState({
		launchpad: 'launchpad',
		name: 'launchpad name',
		details: 'history',
	});

	const setSpaceship = (crew) => {
		data.data.crew.length > 0 ? setRocketImg('Dragon') : setRocketImg(crew);
	};

	//const [landingpad, setLandingpad] = useState('None');
	const spacex = data.data;
	const flightId = spacex.id;
	const name = spacex.name;
	const date = moment(spacex.date_local).format('MMMM Do YYYY, h:mm:ss a');
	const date_precision = spacex.date_precision;
	const details = spacex.details;
	let launchPadId = spacex.launchpad;

	useLayoutEffect(() => {
		const fetchRocket = async () => {
			await axios.get('/rockets/' + spacex.rocket).then((result) => {
				setRocket(result.data.name);
				setSpaceship(result.data.name);
				setAltText(result.data.description);
			});
		};

		if (spacex.rocket) fetchRocket();
	}, [spacex]);

	useEffect(() => {
		const getLaunchPad = async () => {
			await axios.get('/launchpads/' + launchPadId).then((result) => {
				setLaunchpad(result.data);
			});
		};

		if (launchPadId) getLaunchPad();
	}, [spacex]);

	const setRocketImg = (name) => {
		switch (name) {
			case 'Falcon Heavy':
				setImage(falconheavy);
				break;
			case 'Starship':
				setImage(starship);
				break;
			case 'Dragon':
				setImage(dragon);
				break;
			default:
				setImage(falcon9);
		}
	};

	return (
		<div className='col-md-4'>
			<div className='launchContainer' id={`launchBlock${flightId}`}>
				<RocketImg alt={altText} image={image} />

				<div className='launch__mission__name launch__copy'>
					{name}.
				</div>
				<div className='launch__rocket launch__copy'>{rocket}.</div>
				<div className='launch__date launch__copy'>
					{date}
					<br />
					<span className='italic'>
						tentative up to a {date_precision}
					</span>
					.
				</div>
				<div className='launch__site launch__copy launch__tooltip'>
					Launch: {launchpad.name}.
					<div className='launch__site_info launch__copy launch__tooltiptext'>
						<h4>{launchpad.full_name}</h4>
						{launchpad.details}
					</div>
				</div>
				{/* <div className='launch__site launch__copy'>
					Landing: {landingpad}.
				</div> */}
				<hr />
				<div className='launch__details launch__copy'>
					{details !== null
						? details
						: 'There are no details available for this mission.'}
				</div>
			</div>
		</div>
	);
};

export default LaunchCard;
